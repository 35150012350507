<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            Lender List
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3>Company List</h3>
          <br />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <br />
          <v-data-table
            @click:row="handleClick"
            :headers="headers"
            :items="companyList"
            :search="search"
            :items-per-page="-1"
          >
            <template v-slot:[`item.company_type`]="{ item }">
              {{ item.lender ? "Lender " : "" }}
              {{ item.lender && (item.appraiser || item.lawyer) ? ", " : "" }}
              {{ item.appraiser ? "Appraiser " : "" }}
              {{ item.appraiser && item.lawyer ? ", " : "" }}
              {{ item.lawyer ? "Lawyer" : "" }}
            </template>

            <template v-slot:[`item.lender_type`]="{ item }">
              {{ item.lending_profile ? item.lending_profile.lender_type : '' }}
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              {{ item.createdAt | formatDate }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";
import filters from "@/plugins/filters";

export default {
  name: "CompanyList",
  props: [],
  data() {
    return {
      search: "",

      headers: [
        {
          text: "Company Name",
          align: "start",
          filterable: true,
          value: "name",
        },

        {
          text: "Company Type",
          value: "company_type",
        },

        {
          text: "Lender Type",
          value: "lender_type",
        },

        {
          text: "Users",
          value: "users.length",
        },

        {
          text: "Created",
          value: "createdAt",
        },
      ],
      componentLoading: true,
      error: "",
      companyList: [],
    };
  },
  methods: {
    handleClick(value) {
      this.$router.push(`/dashboard/admin/company/read/${value.id}`);
    },
  },
  async mounted() {
    try {
      let companies = await API().get(`/api/internal-admin/company_list`);
      this.companyList = companies.data;
      // console.log('Company List: ', companies);
      this.componentLoading = false;
    } catch (error) {
      console.log(error);
    }
  },
  filters: {
    ...filters,
  },
};
</script>

<style></style>
